import posthog from 'posthog-js';
import { ENV, POSTHOG_HOST, POSTHOG_KEY } from '~/env';
import { locationStore, userStore } from './Auth';
export const posthogInit = () => {
    if (!POSTHOG_KEY || !POSTHOG_HOST) {
        return;
    }
    posthog.init(POSTHOG_KEY, {
        api_host: POSTHOG_HOST,
        autocapture: true,
        capture_pageview: false,
        debug: !ENV.PRODUCTION,
        opt_in_site_apps: true,
    });
    userStore.subscribe((user) => {
        if (!user) {
            return;
        }
        posthog.identify(user.id, {
            name: user.name,
            email: user.email,
            role: user.role,
        });
    });
    locationStore.subscribe(() => {
        posthog.capture('$pageview');
    });
};
